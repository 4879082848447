import * as types from './actionTypes';

const initialState = {
  count: 10,
  page: 1,
  total: 0,
  data: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_STATISTIC_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case types.SET_COUNT:
      return {
        ...state,
        count: action.payload
      };
    default:
      return state;
  }
};
