import uuid from 'uuid/v4';

import { CALL_API } from 'store/middlewares/api';
import * as types from './actionTypes';

export const login = (data) => ({
  [CALL_API]: {
    path: '/dozor/admin/auth',
    method: 'POST',
    config: {
      body: JSON.stringify({
        ...data,
        id_device: uuid(),
        id_platform: 3
      })
    },
    types: [
      types.LOGIN_REQUEST,
      types.LOGIN_SUCCESS,
      types.LOGIN_FAILURE
    ]
  }
});

export const logout = () => ({
  [CALL_API]: {
    path: '/vc2/logout',
    method: 'POST',
    types: [
      types.LOGOUT_REQUEST,
      types.LOGOUT_SUCCESS,
      types.LOGOUT_FAILURE
    ]
  }
});
