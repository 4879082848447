import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ErrorIcon from '@material-ui/icons/Error';

import {
  login
} from 'store/modules/auth/actions';
import { sendNotify } from 'components/Notification'

import './style.css';

class SignIn extends Component {
  state = {
    login: '',
    password: ''
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  onSubmit = event => {
    event.preventDefault();
    this.props.login(this.state)
      .then(() => {
        if (this.props.errorMessage) {
          sendNotify({
            message: (
              <span className="notify">
                <ErrorIcon />
                <span>{this.props.errorMessage}</span>
              </span>
            ),
            autoHideDuration: true
          })
        } else {
          this.props.history.push('/')
        }
      });
  };

  render() {
    const { isAuth } = this.props;
    const { login, password } = this.state;

    if (isAuth) {
      return <Redirect to="/" />
    }

    return (
      <div className="SignIn">
        <form
          className="SignIn__form"
          onSubmit={this.onSubmit}
        >
          <Card>
            <CardContent>
              <Typography color="textSecondary">
                Авторизация
              </Typography>
              <TextField
                id="login"
                label="Логин"
                value={login}
                onChange={this.handleChange('login')}
                margin="normal"
                fullWidth
              />
              <TextField
                id="password"
                label="Пароль"
                value={password}
                onChange={this.handleChange('password')}
                margin="normal"
                type="password"
                fullWidth
              />
            </CardContent>
            <CardActions>
              <Button variant="outlined" color="primary" type="submit">
                Войти
              </Button>
            </CardActions>
          </Card>
        </form>
      </div>
    );
  }
}

export default connect(state => ({
  isAuth: state.auth.isAuth,
  errorMessage: state.auth.errorMessage
}), {
  login
})(SignIn);
