import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducer';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import apiMiddleware from './middlewares/api';
import tokenMiddleware from './middlewares/token';

export default function configureStore (preloadedState) {
  const composedEnhancers = composeWithDevTools(
    applyMiddleware(apiMiddleware, tokenMiddleware)
  );

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
}
