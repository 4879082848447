import React, { Component } from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';

import Photos from 'components/Photos';
import {
  fetchProposal,
  fetchCount,
  changeStatus
} from 'store/modules/proposal/actions';
import { sendNotify } from 'components/Notification'
import Amplitude, {
  APPROVE_FAIL_ADDRESS,
  APPROVE_FAIL_QUALITY,
  APPROVE_SUCCESS
} from 'services/amplitude'

import './style.css';

const platforms = {
  1: 'Android',
  2: 'iOS',
  3: 'Web'
}

class Proposal extends Component {
  timerCount = null;
  timerProposal = null;

  componentDidMount() {
    this.props.fetchCount();
    this.props.fetchProposal()
      .then(() => {
        if (this.props.proposal.id_registration_request === null) {
          this.fetchProposalByTimer()
        }
      });

    this.timerCount = setInterval(() => {
      this.props.fetchCount();
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.timerCount);
    clearInterval(this.timerProposal)
  }

  fetchProposalByTimer = () => {
    this.timerProposal = setInterval(() => {
      this.props.fetchProposal()
        .then(data => {
          if (this.props.proposal.id_registration_request !== null) {
            clearInterval(this.timerProposal);
          }
        })
    }, 5000);
  }

  onChangeStatus = status => () => {
    const { proposal } = this.props;

    const [ city, street, house, flat ] = proposal.address.split(', ');

    let event = ''

    switch (status) {
      case 1:
        event = APPROVE_SUCCESS;
        break;
      case 2:
        event = APPROVE_FAIL_QUALITY;
        break;
      case 0:
        event = APPROVE_FAIL_ADDRESS;
        break;
      default:
        break;
    }

    this.props.changeStatus({
      id_registration_request: proposal.id_registration_request,
      status
    })
      .then(() => {
        Amplitude.logEvent(event, {
          'Платформа': platforms[proposal.id_platform],
          'Идентификатор приложения': proposal.id_app,
          'Город': city,
          'Улица': street,
          'Дом': house,
          'Квартира': flat
        });

        this.props.fetchCount()
        this.fetchProposalByTimer()

        if (this.props.proposal.errorMessage) {
          sendNotify({
            message: (
              <span className="notify">
                <ErrorIcon />
                <span>{this.props.proposal.errorMessage}</span>
              </span>
            ),
            autoHideDuration: true
          })
        }
      });
  };

  render() {
    const { proposal } = this.props;

    return (
      <div className="Proposal">
        {proposal.address !== '' ? (
          <React.Fragment>
            <header className="Proposal__header">
              <Typography>Адрес: {proposal.address}</Typography>
              <Typography>Количество обращений: {proposal.count}</Typography>
            </header>
            <div className="Proposal__content">
              <Photos photos={proposal.photos} />
            </div>
            <div className="Proposal__actions">
              <Button
                variant="outlined"
                color="primary"
                onClick={this.onChangeStatus(1)}
              >
                Подтвердить
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={this.onChangeStatus(2)}
              >
                Плохое качество фото
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={this.onChangeStatus(0)}
              >
                Прописка не совпадает
              </Button>
            </div>
          </React.Fragment>
        ) : proposal.count > 0 ? (
          <div className="Proposal__empty">
            <CircularProgress />
          </div>
        ) : (
          <div className="Proposal__empty">
            <p className="Proposal__empty_title">
              Ты проверил последний имеющийся запрос пользователя, новых запросов нет
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default connect(state => ({
  proposal: state.proposal
}), {
  fetchProposal,
  fetchCount,
  changeStatus
})(Proposal);
