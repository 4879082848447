export const PROPOSAL_REQUEST = 'Proposal/PROPOSAL_REQUEST';
export const PROPOSAL_SUCCESS = 'Proposal/PROPOSAL_SUCCESS';
export const PROPOSAL_FAILURE = 'Proposal/PROPOSAL_FAILURE';

export const COUNT_REQUEST = 'Proposal/COUNT_REQUEST';
export const COUNT_SUCCESS = 'Proposal/COUNT_SUCCESS';
export const COUNT_FAILURE = 'Proposal/COUNT_FAILURE';

export const CHANGE_STATUS_REQUEST = 'Proposal/CHANGE_STATUS';
export const CHANGE_STATUS_SUCCESS = 'Proposal/CHANGE_SUCCESS';
export const CHANGE_STATUS_FAILURE = 'Proposal/CHANGE_FAILURE';
