import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from 'containers/Home';
import SignIn from 'containers/SignIn';
import Notification from 'components/Notification';

class App extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route path="/signin" component={SignIn} />
          <Route component={Home} />
        </Switch>
        <Notification />
      </div>
    );
  }
}

export default App;
