import Amplitude from 'amplitude-js'

const instance = Amplitude.getInstance()

instance.init('52060c921192749f0f9b575d3b126052')

export default instance

export const APPROVE_SUCCESS = 'Проверка паспорта. Успешно'
export const APPROVE_FAIL_QUALITY = 'Проверка паспорта. Плохое качество'
export const APPROVE_FAIL_ADDRESS = 'Проверка паспорта. Прописка не совпадает'
