import { combineReducers } from 'redux';

import auth from './modules/auth/reducer';
import proposal from './modules/proposal/reducer';
import statistic from './modules/statistic/reducer';

export default combineReducers({
  auth,
  proposal,
  statistic
});
