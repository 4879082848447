import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';

import requireAuth from 'components/requireAuth';
import Proposal from 'containers/Proposal';
import Statistic from 'containers/Statistic';
import {
  logout
} from 'store/modules/auth/actions';

import './style.css';

class Home extends Component {
  state = {
    anchorEl: null
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  logout = () => {
    this.handleClose();
    this.props.logout()
      .then(() => this.props.history.replace('/signin'));
  }

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div className="Home">
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" color="inherit" className="header__title">
              <Link to="/">
                Панель управления
              </Link>
            </Typography>
            <Typography color="inherit" className="header__link">
              <Link to="/statistic">
                Статистика
              </Link>
            </Typography>
            <IconButton
              aria-owns={open ? 'menu-appbar' : null}
              aria-haspopup="true"
              onClick={this.handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={this.handleClose}
            >
              <MenuItem onClick={this.logout}>Выйти</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>

        <Switch>
          <Route exact={true} path="/" component={Proposal} />
          <Route path="/statistic" component={Statistic} />
        </Switch>
      </div>
    );
  }
}

export default requireAuth(connect(state => ({}), {
  logout
})(Home));
