import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const requireAuth = WrappedComponent => {
  class RequireAuth extends Component {
    render() {
      const {isAuth} = this.props;

      if (!isAuth) {
        return <Redirect to="/signin" />
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(state => ({
    isAuth: state.auth.isAuth
  }))(RequireAuth);
}

export default requireAuth;
