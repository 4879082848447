import { CALL_API } from 'store/middlewares/api';
import * as types from './actionTypes';

export const fetchProposal = () => ({
  [CALL_API]: {
    path: '/dozor/admin/approve/address',
    method: 'GET',
    types: [
      types.PROPOSAL_REQUEST,
      types.PROPOSAL_SUCCESS,
      types.PROPOSAL_FAILURE
    ]
  }
});

export const fetchCount = () => ({
  [CALL_API]: {
    path: '/dozor/admin/approve/address/count',
    method: 'GET',
    types: [
      types.COUNT_REQUEST,
      types.COUNT_SUCCESS,
      types.COUNT_FAILURE
    ]
  }
});

export const changeStatus = (data) => ({
  [CALL_API]: {
    path: '/dozor/admin/approve/address',
    method: 'POST',
    config: {
      body: JSON.stringify(data)
    },
    types: [
      types.CHANGE_STATUS_REQUEST,
      types.CHANGE_STATUS_SUCCESS,
      types.CHANGE_STATUS_FAILURE
    ]
  }
});
