import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import nanoid from 'nanoid'

export let sendNotify;

const styles = () => ({
  container: {
    position: 'fixed',
    left: 24,
    bottom: 24
  },
  snackbar: {
    position: 'initial',
    marginBottom: 15,
    '&:last-child': {
      marginBottom: 0
    }
  }
});

class Notification extends Component {
  state = {
    messages: []
  }

  componentDidMount() {
    sendNotify = this.send;
  }

  send = notify => {
    let {
      messages
    } = this.state;

    const id = nanoid(4)

    messages.push({
      ...notify,
      id,
      open: true
    });

    this.setState({ messages });

    setTimeout(() => {
      this.handleClose(id)
    }, 6000)
  }

  handleClose = id => {
    const messages = this.state.messages
      .filter(item => item.id !== id)

    this.setState({ messages })
  }

  render() {
    const {
      classes,
    } = this.props;

    const {
      messages
    } = this.state;

    return (
      <div className={classes.container}>
        {messages.map(item => (
          <Snackbar
            className={classes.snackbar}
            key={item.id}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={item.open}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={item.message}
            action={
              item.action
                ? item.action
                : null
            }
          />
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(Notification);
