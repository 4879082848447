import * as types from './actionTypes';

const initialState = {
  id_registration_request: null,
  address: '',
  photos: [],
  count: 0,
  fetch: false,
  errorMessage: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.PROPOSAL_REQUEST:
      return {
        ...state,
        fetch: true,
        errorMessage: ''
      };
    case types.PROPOSAL_SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetch: false
      };
    case types.PROPOSAL_FAILURE:
      return {
        ...state,
        fetch: false
      }
    case types.COUNT_SUCCESS:
      return {
        ...state,
        count: action.payload.count
      }
    case types.CHANGE_STATUS_REQUEST:
      return {
        ...state,
        errorMessage: ''
      }
    case types.CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        id_registration_request: null,
        address: '',
        photos: []
      }
    case types.CHANGE_STATUS_FAILURE:
      return {
        ...state,
        errorMessage: action.payload.body.ERROR
      }
    default:
      return state;
  }
};
