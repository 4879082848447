import React, { Component } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import DateFnsUtils from '@date-io/date-fns';
import { InlineDateTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { format } from 'date-fns/esm';
import _debounce from 'lodash/debounce';
import './style.css';

import ruLocale from 'date-fns/locale/ru';

import {
  fetchStatistic,
  setCount
} from 'store/modules/statistic/actions';

class Statistic extends Component {
  constructor(props) {
    super(props);

    const startDate = new Date();
    const endDate = new Date();

    this.state = {
      from: startDate.setDate(startDate.getDate() - 7),
      to: endDate,
      search: ''
    };
  }

  componentDidMount() {
    this.search();
  }

  handleChangePage = (_, page) => {
    this.search(page + 1);
  }

  handleChangeCount = event => {
    this.props.setCount(event.target.value);
  }

  handleDateChange = type => date => {
    this.setState({ [type]: date });
  }

  handleChangeAddress = event => {
    this.setState({ search: event.target.value });
    this.debounceSearch();
  }

  handleSearch = (event) => {
    event.preventDefault();
    this.search();
  }

  search = page => {
    const { count } = this.props;
    const { search, from, to } = this.state;

    this.props.fetchStatistic({
      count,
      page: page || this.props.page,
      search,
      from: format(new Date(from), 'dd.MM.YYYY HH:mm'),
      to: format(new Date(to), 'dd.MM.YYYY HH:mm')
    });
  }

  debounceSearch = _debounce(this.search, 400);

  render() {
    const { items, count, total, page } = this.props;
    const { from, to, address } = this.state;

    return (
      <div className="Statistic">
        <Paper className="Statistic__filters">
          <form onSubmit={this.handleSearch}>
            <TextField
              className="Statistic__address"
              label="Адрес"
              margin="dense"
              value={address}
              onChange={this.handleChangeAddress}
            />
            <div className="Statistic__dates">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                <div className="picker">
                  <InlineDateTimePicker
                    value={from}
                    onChange={this.handleDateChange('from')}
                  />
                </div>
                <div className="picker">
                  <InlineDateTimePicker
                    value={to}
                    onChange={this.handleDateChange('to')}
                  />
                </div>
              </MuiPickersUtilsProvider>
            </div>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
            >
              Поиск
            </Button>
          </form>
        </Paper>
        <Paper className="Statistic__paper">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Адрес</TableCell>
                <TableCell>Резолюция</TableCell>
                <TableCell>Фотографии</TableCell>
                <TableCell>Дата создания запроса</TableCell>
                <TableCell>Дата резолюции</TableCell>
                <TableCell>Исполнитель</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.address}</TableCell>
                  <TableCell>{item.resolution}</TableCell>
                  <TableCell>
                    {item.photo && item.photo.map((photo, index) => (
                      <a
                        className="statistic__photo"
                        key={index}
                        href={photo}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {index + 1}
                      </a>
                    ))}
                  </TableCell>
                  <TableCell>{item.created_at}</TableCell>
                  <TableCell>{item.approved_at || 'Ожидает'}</TableCell>
                  <TableCell>
                    <a href={`https://live.goodline.info/profile/${item.approver}`}>
                      {item.approver}
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={total}
                  rowsPerPage={count}
                  page={page - 1}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeCount}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </div>
    );
  }
}

export default connect(state => ({
  items: state.statistic.data,
  count: state.statistic.count,
  total: state.statistic.total,
  page: state.statistic.page
}), {
    fetchStatistic,
    setCount
  })(Statistic);
