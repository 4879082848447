import * as types from './actionTypes';

const token = localStorage.getItem('token') || '';

const initialState = {
  token,
  fetch: false,
  isAuth: token !== '',
  errorMessage: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return {
        ...state,
        fetch: true,
        errorMessage: ''
      }
    case types.LOGIN_SUCCESS:
    case types.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload.TOKEN,
        isAuth: true,
        fetch: false
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        token: '',
        isAuth: false,
        fetch: false
      };
    case types.LOGIN_FAILURE:
      return {
        ...state,
        fetch: false,
        errorMessage: action.payload.body.ERROR
      }
    default:
      return state;
  }
}
