import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import IconNavigateBefore from '@material-ui/icons/NavigateBefore';
import IconNavigateNext from '@material-ui/icons/NavigateNext';
import RotateLeft from '@material-ui/icons/RotateLeft';
import RotateRight from '@material-ui/icons/RotateRight';

import './style.css';

class Photos extends Component {
  static defaultProps = {
    photos: []
  };

  state = {
    selectPhoto: 0,
    rotate: 0
  }

  componentDidMount() {
    window.addEventListener('keydown', this.keyboardChangeSelectPhoto);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.photos !== this.props.photos) {
      this.setState({ selectPhoto: 0 })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.keyboardChangeSelectPhoto);
  }

  keyboardChangeSelectPhoto = event => {
    if (event.keyCode === 37) {
      this.changeSelectPhoto(-1)();
    } else if (event.keyCode === 39) {
      this.changeSelectPhoto(1)();
    }
  }

  changeSelectPhoto = count => () => {
    const { photos } = this.props;
    const { selectPhoto } = this.state;

    if (selectPhoto + count >= 0 && selectPhoto + count < photos.length) {
      this.setState({ selectPhoto: selectPhoto + count });
    }
  }

  rotatePhoto = degrees => () => {
    this.setState({
      rotate: this.state.rotate + degrees
    });
  } 

  render() {
    const { photos } = this.props;
    const {
      selectPhoto,
      rotate
    } = this.state;

    return (
      <div className="Photos">
        <div className="Photos__preview">
          <IconButton
            classes={{
              root: 'Photos__button'
            }}
            onClick={this.changeSelectPhoto(-1)}
          >
            <IconNavigateBefore />
          </IconButton>
          <div className="Photo">
            <img
              key={selectPhoto}
              src={photos[selectPhoto]}
              alt={"Выбранная фотография"}
              style={{ transform: `rotate(${rotate}deg)` }}
            />
          </div>
          <div className="Photo__action_bar">
            <IconButton onClick={this.rotatePhoto(-90)}>
              <RotateLeft />
            </IconButton>
            <div className="Photo__counter">
              {selectPhoto + 1} / {photos.length}
            </div>
            <IconButton onClick={this.rotatePhoto(90)}>
              <RotateRight />
            </IconButton>
          </div>
          <IconButton
            classes={{
              root: 'Photos__button'
            }}
            onClick={this.changeSelectPhoto(1)}
          >
            <IconNavigateNext />
          </IconButton>
        </div>
      </div>
    );
  }
}

export default Photos;
