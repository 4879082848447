import * as types from 'store/modules/auth/actionTypes';

export default store => next => action => {
  if (action.type === types.LOGIN_SUCCESS) {
    localStorage.setItem('token', action.payload.TOKEN);
  } else if (action.type === types.LOGOUT_REQUEST) {
    localStorage.removeItem('token');
  }

  next(action);
}
