export const CALL_API = '@@redux-call-api';

const BASE_CONFIG = {
  headers: {
    'Accept': '*/*'
  }
};

let refreshingTokenPromise = null;

const fetchWithToken = (url, config, state) => {
  const token = state.auth.token;
  let options = { ...config };

  if (token !== undefined) {
    options = {
      ...options,
      headers: {
        ...options.headers,
        token
      }
    };
  }

  return fetch(url, options)
    .then(response => {
      return response.json()
        .then(body => ({ meta: response, body }));
    })
    .then(response => {
      if (!response.meta.ok) {
        return Promise.reject(response);
      }
      return response;
    });
};

const refreshToken = (state, next) => {
  next({ type: 'Auth/REFRESH_TOKEN_REQUEST' });

  return fetchWithToken(
    `${process.env.REACT_APP_BASE_API_URL}/refresh/token`,
    { method: 'GET' },
    state
  )
    .then(response => {
      localStorage.setItem('token', response.body.NEW_TOKEN);
      next({ type: 'Auth/REFRESH_TOKEN_SUCCESS', payload: response.body });
    })
    .catch(error => {
      localStorage.removeItem('token');
      next({ type: 'Auth/REFRESH_TOKEN_FAILURE', payload: error });
      window.location = '/signin';
      return Promise.reject(error);
    });
};

export default store => next => action => {
  const callAPI = action[CALL_API];

  if (callAPI === undefined) {
    next(action);
    return;
  }

  let {
    baseURL,
    path,
    method,
    config = {},
    types
  } = callAPI;

  const [REQUEST_TYPE, SUCCESS_TYPE, FAILURE_TYPE] = types;

  next({ type: REQUEST_TYPE });

  const url = baseURL === undefined
    ? `${process.env.REACT_APP_BASE_API_URL}${path}`
    : `${baseURL}${path}`;

  if (config.body !== undefined) {
    config = {
      ...config,
      headers: {
        ...config.headers,
        'Content-Type': 'application/json; charset=utf-8'
      }
    };
  }

  const options = {
    ...BASE_CONFIG,
    ...config,
    method
  };

  const state = store.getState();

  const request = () => fetchWithToken(url, options, state)
    .then(response => {
      if (typeof SUCCESS_TYPE === 'function') {
        next(SUCCESS_TYPE(response))
      } else {
        next({ type: SUCCESS_TYPE, payload: response.body });
      }
    })

  if (refreshingTokenPromise !== null) {
    return refreshingTokenPromise
      .then(() => request())
      .catch(() => request());
  }

  return request()
    .catch(error => {
      if (error.meta.status === 401 && error.body.CODE === 6) {
        if (refreshingTokenPromise === null) {
          refreshingTokenPromise = new Promise((resolve, reject) => {
            refreshToken(state, next)
              .then(() => {
                refreshingTokenPromise = null;
                resolve();
              })
              .catch(refreshTokenError => {
                refreshingTokenPromise = null;
                reject(refreshTokenError);
              });
          })
        }

        return refreshingTokenPromise
          .then(() => request())
          .catch(error => {
            next({ type: FAILURE_TYPE, payload: error });
          })
      } else if (error.body.CODE === 3 || error.body.CODE === 4) {
        localStorage.removeItem('token');
        window.location = '/signin';
      } else {
        next({ type: FAILURE_TYPE, payload: error });
      }
    })
}
