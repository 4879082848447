import { CALL_API } from 'store/middlewares/api';
import * as types from './actionTypes';

export const fetchStatistic = (data) => ({
  [CALL_API]: {
    path: '/dozor/admin/approves',
    method: 'POST',
    config: {
      body: JSON.stringify(data)
    },
    types: [
      types.GET_STATISTIC_REQUEST,
      types.GET_STATISTIC_SUCCESS,
      types.GET_STATISTIC_FAILURE
    ]
  }
});

export const setCount = count => ({
  type: types.SET_COUNT,
  payload: count
});
